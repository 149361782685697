.editor-container {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    '.SFNSText-Regular',
    sans-serif;
  border: solid;
  border-radius: 6px;
  border-color: #ddd;
  border-width: 2px;
}

.editor-container:hover {
  border-color: hsl(280, 40%, 80%);
}

.editor-container:focus {
  border-color: hsl(280, 65%, 75%);
}

.editor-inner {
  background: #fff;
  border-radius: 6px;
  position: relative;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-paragraph {
  font-size: 18px;
  margin: 0;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 34px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-radius: 6px 6px 0 0;
  vertical-align: middle;
  border-bottom: solid;
  border-width: thin;
  border-color: #ccc;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
}
.toolbar button.toolbar-item:hover span.tooltip-text {
  opacity: 1;
  visibility: visible;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item span.tooltip-text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  display: inline-block;
  padding: 5px 8px;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.3;
  background: #d2e9ff;
  color: #005a9e;
  border-radius: 3px;
  transition: 0.3s ease-in;
  z-index: 1;
}

.toolbar button.toolbar-item span.tooltip-text:before {
  content: '';
  position: absolute;
  top: -13px;
  left: 50%;
  margin-left: -7px;
  border: 7px solid transparent;
  border-bottom: 7px solid #d2e9ff;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.6);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  /* margin-right: 8px; */
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(../../../../public/rich_text_editor/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

i.undo {
  background-image: url(../../../../public/rich_text_editor/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(../../../../public/rich_text_editor/arrow-clockwise.svg);
}

i.paragraph {
  background-image: url(../../../../public/rich_text_editor/text-paragraph.svg);
}

i.large-heading {
  background-image: url(../../../../public/rich_text_editor/type-h1.svg);
}

i.small-heading {
  background-image: url(../../../../public/rich_text_editor/type-h2.svg);
}

i.bullet-list {
  background-image: url(../../../../public/rich_text_editor/list-ul.svg);
}

i.numbered-list {
  background-image: url(../../../../public/rich_text_editor/list-ol.svg);
}

i.bold {
  background-image: url(../../../../public/rich_text_editor/type-bold.svg);
}

i.italic {
  background-image: url(../../../../public/rich_text_editor/type-italic.svg);
}

i.underline {
  background-image: url(../../../../public/rich_text_editor/type-underline.svg);
}

i.strikethrough {
  background-image: url(../../../../public/rich_text_editor/type-strikethrough.svg);
}

i.link {
  background-image: url(../../../../public/rich_text_editor/link.svg);
}

.icon.font-color {
  background-image: url(../../../../public/rich_text_editor/font-color.svg);
}
